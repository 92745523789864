import React, { Component } from 'react';

export default class CodeHighlighter extends Component {
    constructor(props) {
        super(props);

        this.lineHighlight = this.lineHighlight.bind(this);
    }

    syntaxHighlight(code) {

        const { regexes, prefix } = this.props;

        const lines = code.split(/\n/);
        let outputArray = [];
        lines.forEach(l => {
            let current = l; 
            regexes.forEach(regexObj => {
                current = current.replace(regexObj.r, `<span class="${prefix}-${regexObj.className}">` + "$1" + "</span>$2");
                current = current.replace(new RegExp(/\$2/), "");
            })
            outputArray.push(current);
        });
        
        outputArray = outputArray.map( s => s.replace(/(\t{1})/g, '<span class="tab" />'))

        return outputArray;
    }

    lineHighlight(lines) {
        const { redList, greenList, prefix } = this.props;

        if (redList) {
            lines = lines.map((l, idx) => {
                if (redList.indexOf(idx) >= 0) {
                    return `<div class="${prefix}-red-highlight"><s>${l}</s></div>`
                }

                return l;
            });
        }

        if (greenList) {
            lines = lines.map((l, idx) => {
                if (greenList.indexOf(idx) >= 0) {
                    return `<div class="${prefix}-green-highlight">${l}</div>`
                }

                return l;
            });
        }
        
        return lines
    }

    breaklines(lines) {
        return lines.join('<br/>')
    }

    render() {
        const { code, prefix } = this.props;

        const html = this.breaklines(this.lineHighlight(this.syntaxHighlight(code)));
        return <div className={`${prefix}-highlighter-container`} 
                    dangerouslySetInnerHTML={{__html: `<span class="highlighter">${html}</span>`}}
                /> 
    }
}