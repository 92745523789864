
import React, { Component } from 'react';
import CodeHighlighter from './code-highlighter';
import "./css/hlsl-highlight.css";

const regexes = [
    
    { className: "key", r: new RegExp(/(struct)/g) }, // key
    { className: "key", r: new RegExp(/(float\d{0,1})/g) }, // key
    { className: "key", r: new RegExp(/(fixed\d{0,1})/g) }, // key
    { className: "key", r: new RegExp(/(half\d{0,1})/g) }, // key
    { className: "key", r: new RegExp(/(inout)/g) }, // key
    { className: "key", r: new RegExp(/(return)/g) }, // key
    
    { className: "function", r: new RegExp(/(distance)/g) }, // built in function
    { className: "function", r: new RegExp(/(frac)/g) }, // built in function
    { className: "function", r: new RegExp(/(dot)/g) }, // built in function
    { className: "function", r: new RegExp(/(max)/g) }, // built in function
    { className: "function", r: new RegExp(/(floor)/g) }, // built in function
    { className: "function", r: new RegExp(/(ceil)/g) }, // built in function
    { className: "function", r: new RegExp(/(step)/g) }, // built in function
    { className: "function", r: new RegExp(/(sin)/g) }, // built in function
    { className: "function", r: new RegExp(/(cos)/g) }, // built in function
    // { className: "function", r: new RegExp(/(tan)/g) }, // built in function
    { className: "function", r: new RegExp(/(tex2D)/g) }, // built in function
    
    // { className: "function", r: new RegExp(/(.*)\(.*\)/g) }, // any function call
    
    { className: "variable", r: new RegExp(/(_ScreenParams)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(Input)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(uv_MainTex)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(_MainTex)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(uv_NoiseTex)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(_NoiseTex)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(_Time)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(uv_BumpMap)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(_LightColor0)/g) }, // built in type
    
    { className: "type", r: new RegExp(/(SurfaceOutputStandard)/g) }, // built in type
    { className: "type", r: new RegExp(/(pragma)/g) }, // built in type
    
    { className: "comment-line", r: new RegExp(/(\/\/.*)/g) }, // comment
        
] 

class HlslHighlighter extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        const { code, redList, greenList } = this.props;

        return <CodeHighlighter 
                         prefix={"hlsl"}
                         code={code}
                         regexes={regexes}
                         redList={redList}
                         greenList={greenList} />
    }
}

export default HlslHighlighter;
