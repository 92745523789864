// Gatsby supports TypeScript natively!
import { PageProps } from "gatsby";
import React from "react";
import Git from "../../components/git";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import HlslHighlighter from "../../components/highlighters/hlsl-highlighter";
import StyledImg from "../../components/styled-img";
import blackandwhite from '../../images/spiderverse_images/black_and_white_circles.png';
import circle5 from '../../images/spiderverse_images/circle_5.png';
import line5 from '../../images/spiderverse_images/line_5.png';
import rotated from '../../images/spiderverse_images/rotated.png';

// all the images for circles, lines, patterns
import singleCircle from '../../images/spiderverse_images/single_circle.png';
import singleLine from '../../images/spiderverse_images/single_line.png';
import unrotated from '../../images/spiderverse_images/unrotated.png';
import uv from '../../images/spiderverse_images/uv.png';
import uv5 from '../../images/spiderverse_images/uv_5.png';
import withaspectandperspective from '../../images/spiderverse_images/withaspectandperspective.png';
import withoutaspect from '../../images/spiderverse_images/withoutaspect.png';
import withoutaspectorpers from '../../images/spiderverse_images/withoutaspectorpers.png';
import SpiderverseDisplayImage from "../../images/sv_screenshot_high_expose.png"


const Spiderverse = (props: PageProps) => {
  
  return <Layout>
    <SEO title="Spiderverse Shader" />
    <div>
		<h1>Spiderverse Shader  <Git url="https://github.com/smoothslerp/spiderverse-shader/blob/master/Assets/spiderverse_surf.shader" /></h1>
		<p>A shader inspired by Spiderman: Into the Spiderverse (Unity/HLSL)</p>
		<StyledImg src={SpiderverseDisplayImage} />		

		<p>
			In this tutorial we will try to achieve one of the effects seen in Into the Spiderverse: 
			the Ben-Day Process named after Benjamin Henry Day Jr. It has an early comic book style of
			shading with dots when an object is exposed to light, and lines when it is not.
		</p>
			
		<p>Let's get right into it.</p>

		<p> The tutorial for this shader is going to be divided into 4 sections: </p>
		<i>
			<ul>
				<li> Patterns </li>
				<li> Screenspace Texturing </li>
				<li> Hooking it all up </li>
				<li> Final Touches </li>
			</ul>
		</i>

		<h1> Patterns </h1>

			<p>
				At the crux of the effect is drawing circles and lines. So let's start with simple code to draw circles and 
				lines on a single uv-texture
			</p>

			<StyledImg src={uv} caption="uv-texture"/>
			<HlslHighlighter code={circleCode}/>

			<p>
				As you might know, the <i>x</i> and <i>y</i>-axes of the uv-texture range from 0 to 1 
				starting from the bottom left.
			</p>

			<p> 
				This function checks the distance between the current uv-coordinate and the center of the uv-texture (0.5, 0.5), 
				and then compares that distance with the desired radius (also ranging from 0 to 1). If we find that the current
				point is within the radius, this function returns 1, 0 otherwise. 
			</p>

			<StyledImg src={singleCircle} caption={"At the beginning, there was a circle"}/>

			<p>
				Then we setup a function that draws a line. This is fairly simple and very similar to the circle function.
			</p>

			<HlslHighlighter code={lineCode} />

			<StyledImg src={singleLine} caption={"And a line"} />

			<p>
				In fairness, this looks like a function that divides the uv-texture into 2 parts on the <i>x</i>-axis;
				and it is. Why it works for as a line drawer will become clearer.
			</p>

			<p>
				Now we look at the initial canvas of a uv-texture again. We already know how to draw circles and lines on them, 
				so let's figure out how to repeat them.
			</p>

			<StyledImg src={uv} caption={"and we're back..."}/>
			<HlslHighlighter code={patternCode}/>			
			<StyledImg src={uv5} caption={"Scale Factor of 5"}/>

			<p>
				The <b>pattern</b> function receives a uv-texture and returns a patterned uv-texture. 
				It works by scaling each axis on the uv-texture by <i>k</i>, and then returns the fractional part (between 0 and 0.99)
				of each whole number interval between 0 and <i>k</i>.

				Since each of the 2 axes of the uv-texture is patterned <i>k</i> times, we get a <i>k-squared</i> grid. 
			</p>

			<StyledImg src={circle5} caption={"Circles plotted on a patterned uv-texture"}/>
			<StyledImg src={line5} caption={"Lines plotted on a patterned uv-texture"}/>

		<h1 id="screenspace"> Screenspace Texturing</h1>
			<p> 	
				Now that we understand how to draw circles and lines on a single and patterned uv-texture, we look
				at how we can get a patterned, flat texture onto a 3D model. 
			</p>

			<HlslHighlighter code={inputCode}/>
			<HlslHighlighter greenList={[4,6]} code={surfaceFuncWithoutPersAndAspect}/>

			<StyledImg src={withoutaspectorpers} caption={"Psychedelic!"}/>
			
			<p>
				In the surface shader above, we start by plugging the screen coordinates into the red and green channels 
				of the Albedo in order to debug it. We can see that the patterned uv-textures appear on the model, 
				but they seem to be stretching and bending. This is because we are viewing the model with a perspective camera
				and  the screen space position has not undergone <u>
					<a href="https://www.learnopengles.com/tag/perspective-divide/" target="_blank">perspective divide</a> 
				</u> yet.
			</p>

			<HlslHighlighter greenList={[4]}code={surfaceFuncWithPersWithoutAspect}/>
			<StyledImg src={withoutaspect} caption={"Closer!"}/>			

			<p> 
				We see now that the uv-texture pattern is no longer warped, but we also notice 
				that the <i>x</i>-axes on the uv-textures are longer than the <i>y</i>-axes, giving it a rectangular shape.
				This is because the current aspect ratio of the screen is 16:9 (i.e not 1:1, which is what the uv-textures are).
				We rescale the <i>x</i>-coordinate of the uv-coordinates by multiplying it by this ratio.
			</p>

			<HlslHighlighter greenList={[5,6]} code={surfaceFuncWithPersWithAspect}/>
			<StyledImg src={withaspectandperspective} caption={"...and then there were squares!"}/>

			<p> 
				We are now done debugging the uv-coordinates, we want to pass them on to the lighting function.
				For this we need to define our own surface shader output. We also change the inout parameter of the surface shader,
				pass the actual fragment colors to the Albedo, and pass the processed uv-coordinates to the lighting function.
			</p>

			<HlslHighlighter code={outputCode}/>			

			<p> 
				
			</p>

			<HlslHighlighter greenList={[1,8,9]}code={surfaceFuncWithPersWithAspectCustom}/>
		
		<h1> Hooking it all up </h1>
			<p> 
				We need decide when to draw circles, when to draw lines, and how to blend it all together.
				Our initial lighting function may look like this. It is a very common ambient and diffuse
				lighting function:
			</p>

			<HlslHighlighter code={initialLightingFunction}/>			

			<p>
				Let's create 2 separate variables for scaling the uv-coordinates by some amount and then use each of them 
				independently to draw lines and circles. 
			</p>

			<HlslHighlighter greenList={[2,3]} code={LightingFunctionPart1}/>			

			<p>
				In the general technique of the Ben-Day process, the radius of the circles is wider where there is more 
				exposure to light. We have the perfect candidate for this: <b><i> NDotL </i></b>. 
			
				We also want to draw lines where there is little to no exposure to light. We also have the perfect candidate for 
				that: <b><i>-NDotL</i></b>.
			</p>

			<HlslHighlighter greenList={[7,9]} code={LightingFunctionPart2}/>

			<p>
				Now, we need to blend this all together. We instantiate a variable called <b>eff</b>, which essentially tracks 
				where the circles <b>or</b> lines effect takes place. We want to use the lighting function colour when neither is to be drawn, 
				white when we want to draw circles, and black when we want to draw lines.
			</p>

			<p> We put everything together in a blending function so that we can show the progress so far. </p>

			<HlslHighlighter greenList={[5,6,8,10]} code={LightingFunctionPart5}/>
			<StyledImg src={blackandwhite} caption={"Mild success! Our favourite kind!"}/>

		<h1> Final Touches </h1>
			<p> 
				We add final touches to complete the effect. Initially, we chose to draw the circles white and 
				the lines black. Let's instead make whatever the lighting function comes back with 
				lighter when we draw circles, and darker when we draw lines. 
			</p>

			<HlslHighlighter greenList={[2,3]} code={LightingFunctionPart6}/>
			<StyledImg src={unrotated} caption={""}/>

			<p>
				Next, we want to be able to rotate the cirlces and lines by some factor, for that we add a rotation function, which 
				like the pattern function, receives the uv-texture and returns it after rotating by some angle, <b>theta</b>.  
			</p> 

			<HlslHighlighter code={rotationFunction} />
			
			<HlslHighlighter greenList={[1]} code={LightingFunctionPart7} />
			<StyledImg src={rotated} caption={"and we're done!"} />

			<p>
				<i>
					The full shader and sample scene 
					can be found <u><a target="_blank" href="https://github.com/smoothslerp/spiderverse-shader/blob/master/Assets/spiderverse_surf.shader">
						in this repository</a></u>. Thanks
					for tuning in, until next time!
					</i>
			</p>
			<p>
				<i>
					Resources: <br/><br/>
					<ul>
						<li><a href="https://thebookofshaders.com/09/">The Book of Shaders (Patterns)</a></li>
						<li><a href="https://www.youtube.com/watch?v=M_Js07VVimY"> 100Drips (Blender Implementation) </a></li>
						<li><a href="https://assetstore.unity.com/publishers/9338">Game-Ready Studios (Model)</a></li>
					</ul>
				</i>
			</p>	
		
	</div>
  	</Layout>
}

const circleCode = `
	float circle(float2 uv, float radius) {
		float d = distance(uv, float2(0.5, 0.5));
		return step(d, radius);
	}
`;

const lineCode = `
	float line(float2 uv, float d) {
		return step(uv.x, d);
	}
`;

const patternCode = `
	float2 pattern(float2 uv, float k) {
		return frac(uv*k);
	}
`

const inputCode = `
	struct Input {
		float2 uv_MainTex;
		float3 vertex;
		float3 viewDir;
		float3 vertexNormal;
		float4 screenPos;
	};
`

const outputCode = `
	struct SurfaceOutputCustom {
		fixed3 Albedo;
		fixed3 Normal;
		fixed3 Emission;
		fixed Alpha;
		float2 textureCoordinates;
	};
`

const surfaceFuncWithoutPersAndAspect = `
	void surf (Input IN, inout SurfaceOutputStandard o) {
		float4 tex = tex2D(_MainTex, IN.uv_MainTex);

		float2 textureCoordinates = IN.screenPos.xy;

		o.Albedo = float3(pattern(textureCoordinates, 50), 0);
		o.Normal = UnpackNormal(tex2D(_BumpMap, IN.uv_BumpMap));
		o.Alpha = 1;
	}
`;

const surfaceFuncWithPersWithoutAspect = `
	void surf (Input IN, inout SurfaceOutputStandard o) {
		float4 tex = tex2D(_MainTex, IN.uv_MainTex);

		float2 textureCoordinates = IN.screenPos.xy / IN.screenPos.w;
		
		o.Albedo = float3(pattern(textureCoordinates, 50), 0);;
		o.Normal = UnpackNormal(tex2D(_BumpMap, IN.uv_BumpMap));
		o.Alpha = 1;
	}
`

const surfaceFuncWithPersWithAspect = `
	void surf (Input IN, inout SurfaceOutputStandard o) {
		float4 tex = tex2D(_MainTex, IN.uv_MainTex);

		float2 textureCoordinates = IN.screenPos.xy / IN.screenPos.w;
		float aspect = _ScreenParams.x / _ScreenParams.y;
		textureCoordinates.x = textureCoordinates.x * aspect;

		o.Albedo = float3(pattern(textureCoordinates, 50), 0);;
		o.Normal = UnpackNormal(tex2D(_BumpMap, IN.uv_BumpMap));
		o.Alpha = 1;
	}
`

const surfaceFuncWithPersWithAspectCustom = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		float4 tex = tex2D(_MainTex, IN.uv_MainTex);

		float2 textureCoordinates = IN.screenPos.xy / IN.screenPos.w;
		float aspect = _ScreenParams.x / _ScreenParams.y;
		textureCoordinates.x = textureCoordinates.x * aspect;

		o.Albedo = tex.rgb;
		o.textureCoordinates = textureCoordinates;
		o.Normal = UnpackNormal(tex2D(_BumpMap, IN.uv_BumpMap));
		o.Alpha = 1;
	}
`

const initialLightingFunction = `
	half4 LightingCustom (SurfaceOutputCustom s, half3 lightDir, half3 viewDir) {
		float2 st = s.textureCoordinates;

		float NDotL = dot(s.Normal, lightDir);
		half diff = max (0, NDotL);

		half4 col;
		half3 l = (s.Albedo * _LightColor0.rgb * diff + _AmbientColor * _AmbientStrength);

		col.rgb = l;
		col.a = s.Alpha;

		return col;
	}
`;

const LightingFunctionPart1 = `
	float2 st = s.textureCoordinates;
	float2 cst = pattern(st, _cScale);
	float2 lst = pattern(st, _lScale);
`;

const LightingFunctionPart2 = `
	float2 st = s.textureCoordinates;
	float2 cst = pattern(st, _cScale);
	float2 lst = pattern(st, _lScale);

	float NDotL = dot(s.Normal, lightDir);
	// circle pattern
	float circles = circle(cst, NDotL);
	// line pattern NDotL*-1 to draw these where the sun dont shine.
	float lines = line(lst, -NDotL);
`;

const LightingFunctionPart5 = `
	half diff = max (0, NDotL);

	half4 col;
	half3 l = (s.Albedo * _LightColor0.rgb * diff + _AmbientColor * _AmbientStrength);
	half3 lDark = half3(0,0,0); // black
	half3 lBright = half3(1,1,1); // white

	float eff = circles + lines;
	
	col.rgb = (1-eff) * l + circles * lBright + lines * lDark;
	col.a = s.Alpha;

	return col;
`;


const LightingFunctionPart6 = `
	half3 l = (s.Albedo * _LightColor0.rgb * diff + _AmbientColor * _AmbientStrength);
	half3 lDark = (1-_DarkenScale) * l;
	half3 lBright = 1 - ((1-_LightenScale) * (1 - l));
`;

const rotationFunction = `
	float2 rotate(float2 uv, float theta) {
		return float2(uv.x * cos(theta) - uv.y * sin(theta),
			      			uv.x * sin(theta) + uv.y * cos(theta));
	}
`;

const LightingFunctionPart7 = `
   	float2 st = rotate(s.textureCoordinates, _Rotation * 3.14159/180);
	float2 cst = pattern(st, _cScale);
	float2 lst = pattern(st, _lScale);
`

export default Spiderverse;
