import React, { Component } from 'react';
import gitPng from '../images/git-img.png'

class Git extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { url } = this.props;

        return <a target="_blank" href={url}>
            <img src={gitPng ? gitPng : ''} className="git-img" width='40px' height='40px'/>
        </a>
    }
}

export default Git;